@media screen and (max-width: 400px) {
  #features {
    padding: 20px;
    width: 111%;
  }
  #about,
  #services,
  #testimonials,
  #team,
  #contact,
  #footer {
    width: 111%;
  }

  #portfolio {
    width: 110%;
  }
}

  #services {
    padding: 20px;
    background-color: blue;
  }

  #titulo {
    position: relative;
    top: 50%;
    width: 13em;
    margin: 0 auto;
    border-right: 2px solid rgba(255, 255, 255, 0.75);
    font-size: 3.6em;
    text-align: center;
    white-space: nowrap;
    overflow: hidden;transform: translateY(-50%);
    animation: typewriter 4s steps(20) 1s 1 normal both,
    blinkTextCursor 500ms steps(20) infinite normal;
}

@keyframes typewriter {
  from {
        width: 0;
  }
  to {
        width: 8em;
  }
}

@keyframes blinkTextCursor {
  from {
        border-right-color: rgba(255, 255, 255, 0.75);
  }
  to {
        border-right-color: transparent;
  }
}


